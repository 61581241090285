import React from 'react'
import { useColorMode } from 'theme-ui'
import { Link } from 'gatsby'
import Logo from './Logo'
import { Heading } from 'theme-ui'

const styles = {
  image: {
    verticalAlign: `middle`,
    width: 150,
    fill: 'var(--theme-ui-colors-omegaDark,#718096)',
    height: 75,
  },
  darkMode: {
    fill: 'var(--theme-ui-colors-omegaDark,#e2e8f0)',
  },
  title: {
    m: 0,
  },
}

export const HeaderLogo = ({ ...props }) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`
  if (!Logo) return null

  return (
    <Heading
      as={Link}
      to={'/'}
      aria-label='Go to homepage'
      variant='h2'
      sx={styles.title}
      {...props}
    >
      <Logo
        //src={logo}
        loading='eager'
        style={
          isDark ? { ...styles.image, ...styles.darkMode } : { ...styles.image }
        }
        {...props}
      />
    </Heading>
  )
}
