// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-blog-core-src-templates-collection-compare-js": () => import("./../../../../packages/blog/core/src/templates/collection.compare.js" /* webpackChunkName: "component---packages-blog-core-src-templates-collection-compare-js" */),
  "component---packages-blog-core-src-templates-compare-js": () => import("./../../../../packages/blog/core/src/templates/compare.js" /* webpackChunkName: "component---packages-blog-core-src-templates-compare-js" */),
  "component---packages-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-core-src-templates-posts-js" */),
  "component---packages-blog-core-src-templates-reviews-js": () => import("./../../../../packages/blog/core/src/templates/reviews.js" /* webpackChunkName: "component---packages-blog-core-src-templates-reviews-js" */),
  "component---packages-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-pages-src-pages-authors-jsx": () => import("./../../../../packages/blog/pages/src/pages/authors.jsx" /* webpackChunkName: "component---packages-blog-pages-src-pages-authors-jsx" */),
  "component---src-elegantstack-pages-blog-pages-contact-jsx": () => import("./../../../src/@elegantstack/pages-blog/pages/contact.jsx" /* webpackChunkName: "component---src-elegantstack-pages-blog-pages-contact-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */)
}

